import React from "react";

// ** Third Party Components
import styled, { css } from "styled-components";

// ** Custom Components
import Button from "components/base/Button";
import LoadingComponent from "../Btn/components/LoadingComponent";
import BtnContent from "../Btn/components/BtnContent";

// ** Custom Styles
import { resolveProps, marginable } from "styles/styledComponentsMixins";

// #####################################################

const StyledButtonLanding = styled(({ ...props }) => (
	<Button {...resolveProps(props, marginable)} />
))(({ $outline }) => {
	return css`
		position: relative;
		display: inline-block;
		cursor: pointer;
		white-space: normal;
		text-wrap: nowrap;
		text-align: center;
		font-size: 1.1rem;
		line-height: 2rem;
		padding: 0 1rem;
		height: 42px;
		width: 100%;
		border-radius: var(--dz-sys-button-landing-border-radius);
		background-color: var(--dz-sys-button-landing-background-color);
		color: var(--dz-sys-button-landing-text-color);
		box-shadow: var(--dz-sys-button-shadow);
		border: 1px solid var(--dz-sys-button-landing-background-color-hover);

		${marginable.css}

		&:hover {
			background-color: var(
				--dz-sys-button-landing-background-color-hover
			);
		}

		&:active {
			background-color: var(
				--dz-sys-button-landing-background-color-active
			);
		}

		&:disabled {
			&:hover,
			&:active {
				background-color: var(--dz-sys-button-landing-background-color);
			}
		}

		${$outline &&
		css`
			color: var(--dz-sys-button-landing-outline-text-color);
			background-color: var(
				--dz-sys-button-landing-outline-background-color
			);
			border: 1px solid
				var(--dz-sys-button-landing-outline-background-color-hover);

			&:hover {
				background-color: var(
					--dz-sys-button-landing-outline-background-color-hover
				);
			}

			&:active {
				background-color: var(
					--dz-sys-button-landing-outline-background-color-active
				);
			}
		`}
	`;
});

// #####################################################

export const ButtonLanding = ({ label, children, loading, ...rest }) => {
	const textContent = children || label;

	return (
		<StyledButtonLanding {...rest}>
			{loading ? (
				<LoadingComponent alwaysLight />
			) : (
				<BtnContent textContent={textContent} />
			)}
		</StyledButtonLanding>
	);
};

// #####################################################
